.menu {
    margin: 0 0 0 30px;
    display: none;
}

.menu > ul > li > span,
.menu > ul > li > a {
    margin: 0 8px;
    font-size: 14px;
    color: #000;
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
}

.menu > ul > li > span:hover,
.menu > ul > li > a:hover {
    color: #5c5c5c;
}

.menu > ul > li:nth-child(7) {
    display: none;
}

.menu > ul > li:nth-child(8) {
    display: none;
}

.active {
    border-bottom: 4px solid #ff1330;
}

@media (min-width: 992px) {
    .menu {
        display: block;
    }

    .menu > ul {
        display: flex;
    }
}

@media (min-width: 1050px) {
    .menu > ul > li > span,
    .menu > ul > li > a {
        margin: 0 10px;
    }
}

@media (min-width: 1120px) {
    .menu > ul > li:nth-child(8) {
        display: unset;
    }
}

@media (min-width: 1220px) {
    .menu > ul > li:nth-child(7) {
        display: unset;
    }
}
