.container {
    position: relative;
    width: 100%;
    background-color: #8ddfea;
}

.wrapper {
    position: relative;
    width: 1200px;
    height: 850px;
    padding: 0 15px;
    margin: 0 auto;
}

.info {
    position: relative;
    z-index: 2;
    margin-top: 50px;
}

.info h1 {
    font-size: 190px;
    font-weight: 700;
    margin-bottom: 40px;
    color: #222a4b;
}

.info h2 {
    font-size: 50px;
    font-weight: 300;
    margin-bottom: 20px;
    color: #00a9e1;
}

.info p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 20px;
    color: #000000;
}

.btn {
    font-size: 18px;
    font-weight: 300;
    color: #ffffff;
    border-bottom: 2px solid #007ca1;
    padding: 10px 41px;
    border-radius: 5px;
    display: inline-block;
    margin: 10px 0;
    background-color: #00a9e1;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    text-decoration: none;
    cursor: pointer;
}

.btn:hover {
    background: #0090c0;
    color: #ffffff;
    text-decoration: none;
}

.bear {
    position: relative;
    top: -420px;
    width: 1120px;
    height: 856px;
    background: url("/static/img/bear.png") no-repeat;
    background-size: 1034px 790px;
}

.zzz {
    position: absolute;
    top: 526px;
    width: 74px;
    height: 124px;
    background: url("/static/img/z-1.gif") no-repeat;
    background-size: 74px 124px;
}

@media (max-width: 1200px) {
    .wrapper {
        width: 768px;
        height: 870px;
    }

    .info {
        margin-top: 35px;
        text-align: center;
    }

    .info h1 {
        font-size: 140px;
        margin-bottom: 40px;
    }

    .info h2 {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .info p {
        font-size: 17px;
        line-height: 27px;
        margin-bottom: 20px;
    }

    .bear {
        top: -110px;
        width: 738px;
        height: 564px;
        background-size: 738px 564px;
    }

    .zzz {
        top: 370px;
        width: 53px;
        height: 88px;
        background-size: 53px 88px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        width: 480px;
        height: 730px;
    }

    .bear {
        top: -40px;
        width: 450px;
        height: 344px;
        background-size: 450px 344px;
    }

    .zzz {
        top: 220px;
        width: 32px;
        height: 54px;
        background-size: 32px 54px;
    }
}

@media (max-width: 480px) {
    .wrapper {
        width: 360px;
        height: 550px;
    }

    .info {
        margin-top: 20px;
    }

    .info h1 {
        font-size: 90px;
        margin-bottom: 30px;
    }

    .info h2 {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .info p {
        font-size: 15px;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .bear {
        top: -40px;
        width: 330px;
        height: 252px;
        background-size: 330px 252px;
    }

    .zzz {
        top: 160px;
        width: 24px;
        height: 40px;
        background-size: 24px 40px;
    }
}
