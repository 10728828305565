.header {
    padding: 0 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 57px;
    min-height: 57px;
    background-color: #fff;
    z-index: 4;
    position: relative;
    border-bottom: 1px solid #e5e6e7;
}

.menu {
    width: 100%;
    margin-left: 14px;
}

.logo {
    cursor: pointer;
    flex: 1;
    text-align: left;
}

.login {
    display: none;
    padding-right: 10px;
}

.notificationIcons {
}

.menuProfile {
    position: relative;
    margin: 0 0 0 28px !important;
    white-space: nowrap;
}

.signinButton {
    display: inline-block;
    vertical-align: middle;
    height: 35px;
    width: 100px;
    line-height: 35px;
    text-align: center;
    background-color: #ff1739;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
}

.menuLink1,
.menuLink2 {
    color: #ff1739;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
}

.menuLink1:hover,
.menuLink2:hover {
    text-decoration: underline;
}

.menuLink1 {
    display: block;
}

.menuLink2 {
    display: none;
}

@media (max-width: 600px) {
    .menuLink1 {
        display: none;
    }

    .menuLink2 {
        display: block;
        margin-top: 5px;
    }
}

@media (min-width: 768px) {
    .headerSticky {
        composes: header;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        left: 0;
        right: 0;
    }
}

@media (min-width: 990px) {
    .header {
        z-index: 4;
    }

    .menu {
        flex: 1;
        margin-left: 0;
    }

    .logo {
        cursor: pointer;
        flex: none;
    }

    .login {
        display: block;
    }

    .menuProfile {
        display: block;
        margin: 0 40px 0 0;
    }
}
