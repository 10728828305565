.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: stretch;
}

@media (min-width: 768px) {
    .wrapper {
        min-height: 100vh;
        height: auto;
    }
}
